<template>
  <div>
    <h3 ref="intro">
      Proportions
    </h3>
    <br>
    <p>
      In mathematics, two quantities are said to be proportional if they increase or decrease at the same rate.
      For example, consider a stack of bricks. As you add more bricks to the stack, the height and weight of the stack increase at the same rate.
      Thus, the height of the stack is proportional to its weight.
      <br><br>
      For example, let's say a stack of 2 bricks is 30 cm in height and 15 kg in weight. Then:
      <ul class="a mx-3 my-3">
        <li> A stack of 4 bricks will be 60 cm in height and 30 kg in weight. <br><br> </li>
        <li> A stack of 6 bricks will be 90 cm in height and 45 kg in weight. </li>
      </ul>
    </p>
    <h3 ref="intro">
      Proportions and Equal Ratios
    </h3>
    <br>
    <p>
      When the two entities are proportional to each other, their ratio always remains the same.
      Let's calculate the ratio of height to weight for the stacks of bricks considered above.
      <ul class="a mx-3 my-3">
        <li>
          For the stack of 2 bricks, the ratio of height to weight is 30:15, which is equivalent to 2:1.<br><br>
        </li>
        <li>
          For the stack of 4 bricks, the ratio of height to weight is 60:30, which is again equivalent to 2:1. <br><br>
        </li>
        <li>
          For the stack of 6 bricks, the ratio of height to weight is 90:45, which is again equivalent to 2:1.<br><br>
        </li>
      </ul>
      Thus, we see that the ratio of height to weight remains the same no matter the number of bricks in the stack.
    </p>
    <h3 ref="pg">
      MagicGraph | Equal Proportions
    </h3>
    <br>
    <h5> Getting Started </h5>
    <p>
      Shown in the MagicGraph are two pizzas. Pizza 1 has a total of 5 slices, out of which some slices are taken and some are left.
      <br><br>
      The ratio of taken to leftover slices is shown.
      <br><br>
      Pizza 2 consists of 10 slices and none of the slices is yet taken. Your goal is take enough number of slices so that the ratio of
      taken to leftover slices in Pizza 2 is same as that in Pizza 1. <br><br>
      Tap on a slice to take it.
    </p>
    <h5> Icons on the MagicGraph </h5>
    <p>
      You can tap on <i class="shuffler ma-1" /> icon  to shuffle and generate a new shape. You can tap on the
      <i class="tester ma-1" /> icon to erase and start over.
    </p>
    <br>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'Proportions',
  async created () {
    await this.$store.dispatch('navigation/getData');
    let title = 'Proportions';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'Parallel Lines', img:'/assets/number-1.svg', action: () => this.goto('intro')},
      {title: 'Transversal', img:'/assets/number-2.svg', action: () => this.goto('trans')},
      {title: 'Pair of Angles', img:'/assets/number-3.svg', action: () => this.goto('angles')},
      {title: 'MagicGraph',img:'/assets/touch.svg', action: () => this.goto('pg')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newMath =true;
    this.$store.commit('navigation/replaceMath', newMath);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule=true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
  metaInfo() {
  return{ title: 'Proportions',
          titleTemplate: '%s - Learn interactively',
          meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                  {name: 'description', content: 'Learn interactively how to draw a free body diagram'}
                ]
        }
   },
}
</script>
<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
@import 'src/styles/screen-sizes.scss';
</style>
