// Import the edliy_utils
import {
    makeResponsive,
    placeTitle,
    placeImage,
    placeInput,
    placeSlider,
    hoverMe,
    placeRec,
    hiddenPt,
    fixedPt,
    clearInputFields,
    dragMe,
    placeArrow,
    placeGravity,
    placeMiddleText,
    placeLine,
    placePoint,
    placeGlider,
    placeRuler,
    placeLeftText,
    placeRightText,
    placeSliderSwitch,
    placeCircle,
    placeLogo,
    placeShuffle,
    placeTest,
    placeBoldText
} from '../../../common/edliy_utils-fractions';
const Boxes = {
  box1: function () {
	JXG.Options.board.minimizeReflow = 'none';
  JXG.Options.point.showInfoBox=false;
  JXG.Options.point.highlight=false;
  JXG.Options.image.highlight=false;
  JXG.Options.text.highlight=false;
  JXG.Options.text.fixed=true;
  JXG.Options.curve.highlight=false;
  JXG.Options.text.cssDefaultStyle='fontFamily:Oswald;'
  var brd1 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-1, 13, 14, -2],keepaspectratio: true, axis:false, ticks:true, grid:true, showCopyright:false, showNavigation:false, pan:{enabled:false}, zoom:{enabled:false}});
  //brd1.suspendUpdate();
  makeResponsive(brd1);
  brd1.options.layer['image'] =12;
  brd1.options.layer['circle'] =13;
  brd1.options.layer['chart'] =14;
  brd1.options.layer['sector'] =15;
  brd1.options.layer['line'] =15;
//	brd1.create('text', [6, 11.5, 'Fraction'],{highlight:false, display:'internal', anchorX:'middle', anchorY:'middle', CssStyle:'fontFamily:Oswald;fontWeight:bold',fontSize:function(){return Math.round(32*brd1.canvasWidth/800.)}, fixed:true});
  placeTitle(brd1, 'Proportions', 'Equal Proportions of Leftover Pizza Slices');
  placeLogo(brd1);
  var reload = placeShuffle(brd1);
  var test = placeTest(brd1);
  var k = 0;
  var lenArr = [5, 6, 4, 5, 5];
  var fracArr = [3, 3, 2, 2, 4];
  //var charts=[];
  placeImage(brd1, '/assets/pizza.svg', 0, 1, 6,0);//
  placeBoldText(brd1, 3, 0.5, 'Pizza 1');
  placeBoldText(brd1, 3, 8, function(){ return 'Taken : Leftover = '+ fracArr[k]+' : '+lenArr[k]});
  placeBoldText(brd1, 10, 0.5, 'Pizza 2');
//var circ = placeCircle(brd1, [3, 4], [5.5,4], 'black');
//circ.setAttribute({strokeWidth:2, strokeColor:'black'});
  var charts =brd1.create('circle', [[3,4], [6, 4]], {visible:true,
  fillOpacity:0.25, dash:1, strokeColor: 'blue', strokeWidth:0, fillColor:'white', layer: 2, highlightStrokeColor: 'blue'})
  var right = placeImage(brd1, '/assets/check.svg', 12.5, 7.5, 1.,  0);
  var wrong = placeImage(brd1, '/assets/cross.svg', 12.5, 7.5, 1.,  0);
  right.setAttribute({visible:false});
  wrong.setAttribute({visible:false});
  test.setLabel('Tap to Test Your Answer')
  var yay = placeMiddleText(brd1, 6.5, 9.5, 'Yes these two proportions are equal');
  var nay = placeMiddleText(brd1, 6.5, 9.5, 'No, these two proportions are not equal');
  yay.setAttribute({visible:false});
  nay.setAttribute({visible:false});
  test.label.setAttribute({visible:false, alignX:'middle', offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
  test.on('over', function () {this.label.setAttribute({visible:true});});
  test.on('out', function () {this.label.setAttribute({visible:false});});
  test.on('down', function(){
    if(fracArr[k]/lenArr[k] == test()/10.)
    {right.setAttribute({visible:true});
     wrong.setAttribute({visible:false});
     yay.setAttribute({visible:true});
     nay.setAttribute({visible:false});
  }
    else {
    wrong.setAttribute({visible:true});
    right.setAttribute({visible:false});
    yay.setAttribute({visible:false});
    nay.setAttribute({visible:true});
  }
  });
  /////////////////////////////////////////////////////////////////////////////////////////////
  reload.setLabel('Tap to Shuffle')
  reload.label.setAttribute({visible:false, alignX:'middle', offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
  reload.on('over', function () {this.label.setAttribute({visible:true});});
  reload.on('out', function () {this.label.setAttribute({visible:false});});
  reload.on('down', function(){
    right.setAttribute({visible:false});
    wrong.setAttribute({visible:false});
    yay.setAttribute({visible:false});
    nay.setAttribute({visible:false});
    if(k<5){
    k+=1;
    }
    else{k=0}
    ini2(); fun2();});
/////////////////////
  var sect2=[];
  var ini2 = function()
  {
    brd1.removeObject(sect2,true);
  }
/////////////////
 var status2 =[];
 var fun2 = function(){
 for(let i=0; i<lenArr[k]; i++)
 {
   sect2[i] =brd1.create('sector', [[3,4],
    [3+3*Math.cos((i+1)*2*Math.PI/Math.max(lenArr[k],1)), 4+3*Math.sin((i+1)*2*Math.PI/Math.max(lenArr[k], 1))],
    [3+3*Math.cos((i+2)*2*Math.PI/Math.max(lenArr[k],1)), 4+3*Math.sin((i+2)*2*Math.PI/Math.max(lenArr[k], 1))]],
   {strokeColor:'black', strokeWidth:1, fillColor:'OldLace', fillOpacity:function(){if(i<fracArr[k]){return 1}else{return 0}}});
   status2[i] = 0;
   sect2[i].setAttribute({dash:2, center:{visible:false}, radiusPoint:{visible:false}, anglePoint:{visible:false}});
 }
};
 var test2 = function()
 { let total2=0;
   for(let i=0; i<lenArr[k]; i++)
   {
     total2 = total2 + Math.abs(Math.sin(status2[i]*Math.PI/2));
   }
   return total2;
 }
fun2();
/////////////////////////////////////////////////// RIGHT PIZZA ////////////////////////////////////////
placeImage(brd1, '/assets/pizza.svg', 7, 1, 6,0);//
var charts2 =brd1.create('circle', [[10,4], [13, 4]], {visible:true,
fillOpacity:0.25, dash:1, strokeColor: 'black', strokeWidth:0, fillColor:'white', layer: 2, highlightStrokeColor: 'black'})
var sect=[];
var ini = function(){
brd1.removeObject(sect,true);
}
/////////////////
 const lenArr2 = [10, 10, 10, 10, 10];
 var status =[];
 var fun = function(){
 for(let i=0; i<lenArr2[k]; i++)
 {
   sect[i] =brd1.create('sector', [[10,4],
    [10+3*Math.cos((i+1)*2*Math.PI/Math.max(lenArr2[k],1)), 4+3*Math.sin((i+1)*2*Math.PI/Math.max(lenArr2[k], 1))],
    [10+3*Math.cos((i+2)*2*Math.PI/Math.max(lenArr2[k],1)), 4+3*Math.sin((i+2)*2*Math.PI/Math.max(lenArr2[k], 1))]],
   {strokeColor:'black', strokeWidth:1, fillOpacity:0.});
   status[i] = 0;
   sect[i].setAttribute({dash:2, center:{visible:false}, radiusPoint:{visible:false}, anglePoint:{visible:false}});
 }
};
 var test = function()
 { let total=0;
   for(let i=0; i<lenArr2[k]; i++)
   {
     total = total + Math.abs(Math.sin(status[i]*Math.PI/2));
   }
   return total;
 }
ini(); fun();

brd1.on('up', function(evt)
{
  let coords = brd1.getMousePosition(evt);
  for(let i=0; i<lenArr2[k]; i++)
  {
    if(sect[i].hasPointSector(coords[0], coords[1])==true)
    { status[i] +=1;
      right.setAttribute({visible:false});
      wrong.setAttribute({visible:false});
      sect[i].setAttribute({fillOpacity:Math.abs(Math.sin(status[i]*Math.PI/2)), fillColor:'OldLace'});
    }
  }
}
);
placeBoldText(brd1, 10, 8, function(){ return 'Taken : Leftover = '+ (test()).toFixed(0)+' : '+lenArr2[k]});
////////////////////////////////////////////////////////////////////////////////////////////////////////////////
brd1.unsuspendUpdate();
},
}
export default Boxes;
